import React, { useState, useRef } from 'react';
import { updateProfile } from 'firebase/auth';
import { signUpUsingFirebase, signInWithGoogle } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import { updateUserData } from './UserContext';
import '../App.css';

const SignUp = () => {
    const [view, setView] = useState('signup');
    const [username, setUsername] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const userCredentialRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [googleErrorMessage, setGoogleErrorMessage] = useState('');

    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();

        try {
            userCredentialRef.current = await signUpUsingFirebase(email, password);
            // TODO: await sendEmailVerification(userCredential.user);
            await updateProfile(userCredentialRef.current.user, { displayName: displayName });
            setView('username');
        }
        catch (err) {
            console.log(err);
            setErrorMessage('Invalid input. Please try again.');
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            userCredentialRef.current = await signInWithGoogle();
            setDisplayName(userCredentialRef.current.user.displayName);
            setView('username');
        } catch (err) {
            console.log(err);
            setGoogleErrorMessage('Invalid credentials. Please try again.');
        }
    };

    const handleUsername = async (e) => {
        e.preventDefault();

        try {
            await updateUserData(userCredentialRef.current.user, displayName, username);
            navigate('/profile');
        }
        catch (err) {
            console.log(err);
            setErrorMessage('Invalid input. Please try again.');
        }
    };

    return (
        <div className="form-page">
            <h2>Sign Up</h2>
            {view === 'signup' && (
                <div>
                    <form onSubmit={handleSignUp}>
                        <div className="center">
                            <input
                                id="displayName"
                                type="text"
                                value={displayName}
                                onChange={e => setDisplayName(e.target.value)}
                                placeholder="Full Name"
                                maxLength={100}
                                required
                            />
                        </div>
                        <div className="center">
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder="Email"
                                maxLength={255}
                                required
                            />
                        </div>
                        <div className="center">
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder="Password"
                                maxLength={100}
                                required
                            />
                        </div>
                        <div className="center">
                            <p className="errorMessage">{errorMessage}</p>
                        </div>
                        <div>
                            <button className="app-button" type="submit">Create account</button>
                        </div>
                    </form>
                    <p className="horizontal-line" />
                    <div>
                        <p>OR</p>
                        <button className="app-button" onClick={handleGoogleSignIn}>Continue with Google</button>
                    </div>
                    <p className="horizontal-line" />
                    <div className="center">
                        <p className="errorMessage">{googleErrorMessage}</p>
                    </div>
                    <div>
                        <p>Already have an account? <button className="app-button" onClick={() => navigate('/login')}>Log In</button></p>
                    </div>
                </div>
            )}
            {view === 'username' && (
                <div>
                    <form onSubmit={handleUsername}>
                        <div className="center">
                            <input
                                id="username"
                                type="text"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                placeholder="Choose a username (Up to 16 characters)"
                                maxLength={16}
                                required
                            />
                        </div>
                        <div>
                            <button className="app-button" type="submit">Save username</button>
                            <button className="app-button" onClick={() => setView('signup')}>Back</button>
                        </div>
                    </form>
                </div>
            )}

        </div>
    );
};

export default SignUp;
