import React from 'react';
import FeedItem from './FeedItem';

const Feed = ({ mementos }) => {
  return (
    <div>
      <h3>Mementos from most recent sessions:</h3>
      {mementos.map(memento => <FeedItem key={memento.id} memento={memento} />)}
    </div>
  );
};

export default Feed;
