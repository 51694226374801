import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function IntroMindfulness() {
  const navigate = useNavigate();

  const handleHome = () => {
      navigate('/');
  };

  return (
    <div className="page-content">
      <h2>Mindfulness - A Quick Intro</h2>
      <p>Mindfulness is the practice of maintaining moment-by-moment, non-judgemental awareness of our thoughts, feelings, bodily sensations, and surrounding environment. 
        It encourages us to perceive and accept our thoughts, feelings and sensations clearly, and without being influenced by them. 
        Getting good at this practice leads to us being able to quiet our minds, expand our consciousness to include not just our mind and body, 
        but also our surroundings, and helps us see things that exist or are emerging in the present moment far more clearly. 
        This, in turn, helps us respond to them far more authentically and effectively.</p>
      
      <h3>Scientific Basis for Mindfulness</h3>
      <p>While the practice of Mindfulness is rooted in ancient meditation traditions, it has also garnered significant attention in the scientific community over recent decades. 
        Modern research has provided a robust evidence-based foundation for its underlying mechanism as well as its benefits, making it particularly meaningful and useful in our modern times.</p>
      
      <ul>
        <li><b>Mindfulness as a Fundamental Aspect of Life:</b> The Free Energy Principle and the related concept of Active Inference are currently our best theories for explaining the phenomenon of life based purely on physics. Mindfulness can acutally be seen as a practice that aligns the thinking and behavior of complex living organisms such as ourselves to the Free Energy Principle. (For a detailed explanation of this connection, please <a href="https://meaning.lifevisor.ai" target="_blank" rel="noreferrer">look at my book</a>.)</li>
        <li><b>Neuroscientific Evidence:</b> Functional MRI studies have shown that regular mindfulness practice can lead to structural changes in the brain. Specifically, increased gray matter density in the hippocampus (involved in learning and memory) and a reduction in the size of the amygdala (associated with stress and anxiety) have been observed.</li>      
        <li><b>Cognitive Benefits:</b> Research indicates that mindfulness improves cognitive functions such as attention, working memory, and executive function. Mindfulness training has been shown to enhance focus, reduce mind-wandering, and improve cognitive flexibility.</li>
        <li><b>Emotional Regulation:</b> Studies demonstrate that mindfulness helps in regulating emotions by reducing reactivity to emotional stimuli and enhancing emotional resilience. This is particularly relevant in reducing symptoms of anxiety, depression, and stress-related disorders.</li>
        <li><b>Physiological Benefits:</b> Mindfulness has been linked to various physiological benefits, including reduced blood pressure, improved immune function, and better regulation of stress hormones like cortisol. These findings suggest a holistic impact on overall health.</li>
        <li><b>Clinical Applications:</b> Mindfulness-Based Stress Reduction (MBSR) and Mindfulness-Based Cognitive Therapy (MBCT) are widely used clinical interventions. They have been validated in numerous randomized controlled trials for their efficacy in treating a range of conditions from chronic pain to depression.</li>
      </ul>
      
      <p>By integrating mindfulness into your life, you leverage an evidence-based, scientifically-backed method to foster greater awareness of yourself and your situation, leading to more effective action in general. It also helps to reduce stress, anxiety or depression, and improve overall quality of life over the longer term.</p>

      <div className="dialog-button-bar">
          <button className="app-button" onClick={handleHome}>Home</button>
      </div>
    </div>
  );
}

export default IntroMindfulness;
