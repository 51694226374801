import React from 'react';
import './Modal.css';  // Assume basic CSS for modal

const Modal = ({ show, title, onClose, children }) => {
  if (!show) {
    return null;
  }

  const handleCloseClick = (e) => {
    e.stopPropagation(); // Prevent the modal close handler from firing when clicking inside the modal
    onClose();
  };

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-title-bar">
          <h2 className="modal-title">{title}</h2>
          <button className="close-button" onClick={handleCloseClick}><img src="/close.svg" width="16" height="16" alt="close"/></button>
        </div>
        <div className="modal-message">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
