import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import './TitleBar.css'; // Ensure this path is correct

const TitleBar = () => {
    const { currentUser } = useUser();
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/');
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    // Handle clicks outside the menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className="title-bar">
            <button className="app-icon-button" onClick={handleHomeClick}><img src="/app-icon.png" alt="App Icon" className="app-icon" /></button>
            <h1 className="app-name" onClick={handleHomeClick}>MindFeed<br/><span style={{fontSize: '12px', fontWeight: 'normal', fontStyle: 'italic', verticalAlign: 'text-top', paddingLeft: '20px'}}>(preview)</span></h1>
            <span className="app-tagline">Proven Practices for Creating Meaningful Lives</span>
            <button onClick={toggleMenu} className="menu-button">
                {currentUser ? <img src="/loggedin.svg" alt="Menu" className="menu-icon" /> : "☰"}
            </button>
            {isMenuOpen && (
                <div className="dropdown-menu" ref={menuRef}>
                    <ul>
                        <li onClick={closeMenu}><a href="/">Home</a></li>
                        <li onClick={closeMenu}>{ currentUser ? <a href="/profile">Profile</a> : <a href="/login">Login</a> }</li>
                        <li><a href="#">Resources</a>
                            <ul>
                                <li onClick={closeMenu}><a href="/mindfulness">Mindfulness</a></li>
                                <li onClick={closeMenu}><a href="/stoicref">Stoic Reflection</a></li>
                                <li onClick={closeMenu}><a href="/6cedref">"6CED" Reflection</a></li>
                            </ul>
                        </li>
                        <li onClick={closeMenu}><a href="/about">About</a></li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TitleBar;
