import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function About() {
  const navigate = useNavigate();

  const handleHome = () => {
      navigate('/');
  };

  return (
    <div className="page-content">
      <h2>About MindFeed</h2>
      <p>MindFeed is being developed by me, <a href="https://bhalerao.org" target="_blank" rel="noreferrer">Vin Bhalerao</a>, as a part of my <a href="https://lifevisor.ai" target="_blank" rel="noreferrer">LifeVisor.AI project</a>.</p>
      <p>LifeVisor is my long term personal project that aims to make life more meaningful, happier and productive by bringing it in alignment with the universe's inherent "6CED" Tendencies of Coherence, Complexity, Continuity (of existence or identity), Curiosity, Creativity, Consciousness, Evolution and Diversity.</p>
      <p>For more details about the underlying science and philosophy behind this approach towards finding meaning, purpose and hope in your life, please take a look at my book, <a href="https://meaning.lifevisor.ai" target="_blank" rel="noreferrer">An Engineer's Search for Meaning</a>.</p>
      <p></p>
      <h3>Here is how MindFeed works:</h3>
      <ul className="items-list">
        <li>MindFeed gets you started by showing you healthy and yet fun and engaging content, such as short poems or the opening lines of intriguing sci-fi or romance stories.</li>
        <li>It then invites you to try your own hand at creating such content.</li>
        <li>Anyone can do this easily because the content gets automatically generated as a byproduct of going through one of the guided practice sessions included at the top of the Home page.</li>
        <li>We have 4 such practices available currently: "Mindful Moment", "Purposeful Morning", "Hopeful Evening" and "Meaningful Life".</li>
        <li>These practices have strong scientific and philosophical foundations and there is a lot of evidence that they lead to healthier, happier, more productive and ultimately more meaningful lives.</li>
        <li>MindFeed guides you step by step through each practice, helps you record your experience of the practice, and then uses Generative AI to automatically create the aforementioned content as a memento of the session.</li>
        <li>You can choose to share these mementos as they are or edit them to your liking before doing so.</li>
        <li>Sharing them with your friends allows you to "pay it forward" by motivating them to try these practices on their own.</li>
        <li>Note that MindFeed only gives you a quick intro to the aforementioned practices. Eventually, you will need to learn the practice in more depth from an expert.</li>
      </ul>
      <p>Feedback? Suggestions? Please use the form on <a href="https://meaning.lifevisor.ai/#contact" target="_blank" rel="noreferrer">this page</a>.</p>
    
      <div className="dialog-button-bar">
          <button className="app-button" onClick={handleHome}>Home</button>
      </div>
    </div>
  );
}

export default About;
