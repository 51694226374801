import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext'; // Make sure to set up a UserContext to manage user state

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useUser(); // Access the user context
  const location = useLocation();

  if (!currentUser) {
    // Redirect them to the /login page, but save the current location they were trying to go to
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
