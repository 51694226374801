import React, { useState, useEffect } from 'react';
import { getIdToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { MementoType, MementoSubtype, TendencyType } from './Utils.js';
import Modal from './Modal';
import '../App.css';

function getRandomMementoSubtype() {
  const values = [MementoSubtype.SHORTPOEM, MementoSubtype.QUOTE];
  const randomIndex = Math.floor(Math.random() * values.length);
  return values[randomIndex];
}

function MorningReflection() {
  const { currentUser } = useUser();
  const [view, setView] = useState('intro');
  const [items, setItems] = useState({ item1: '', item2: '', item3: '' });
  const [tendenciesList, setTendenciesList] = useState({ tendencies1: 0, tendencies2: 0, tendencies3: 0 });
  const [subtype, setSubtype] = useState(getRandomMementoSubtype());
  const [errorMessage, setErrorMessage] = useState('');
  const [waitingMessage, setWaitingMessage] = useState('');

  const handlePromptItemChange = (e) => {
    setItems({ ...items, [e.target.name]: e.target.value });
  };

  const handleTendenciesChange = (e) => {
    let tendencies = tendenciesList[e.target.name];
    tendencies &= ~e.target.value;
    if (e.target.checked)
      tendencies |= e.target.value;
    setTendenciesList({ ...tendenciesList, [e.target.name]: tendencies });
  };

  const getTendencies = (checkboxSet) => {
    let tendencies = 0;
    let tendenciesArray = document.querySelectorAll('input[name="' + checkboxSet + '"]:checked');

    Array.prototype.forEach.call(tendenciesArray, function(el) {
        tendencies = tendencies | el.value;
    });

    return tendencies;
  };

  const handleSubtypeChange = (e) => {
    setSubtype(Number(e.target.value));
  };

  const updateView = (e, index, view) => {
    e.preventDefault();

    if (index > 0) {
      setItems({ ...items, [`item${index}`]: items[`item${index}`].trim() });
      setTendenciesList({ ...tendenciesList, [`tendencies${index}`]: getTendencies(`tendencies${index}`) });
    }

    setView(view);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = { type: MementoType.MORNING, subtype: subtype, items: Object.values(items), tendencies_list: Object.values(tendenciesList) };
      let response;

      setWaitingMessage('Generating your memento, this may take a few seconds...');

      if (currentUser) {
        const idToken = await getIdToken(currentUser);

        response = await fetch('/api_prompt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify(payload),
        });  
      } else {
        response = await fetch('/api_prompt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });  
      }

      const responseData = await response.json();

      if (response.ok) {
        setWaitingMessage('');

        window.location.href = '/memento/' + responseData.id;
      } else {
        throw new Error('Failed to submit prompt items');
      }
    } catch (error) {
      setWaitingMessage('');
      console.log('Error: ', error);
    }
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  // handle enter key
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        switch (view) {
          case 'intro': updateView(event, 0, 'identify'); break;
          case 'identify': updateView(event, 0, 'act1'); break;
          case 'act1': updateView(event, 1, 'act2'); break;
          case 'act2': updateView(event, 2, 'act3'); break;
          case 'act3': updateView(event, 3, 'submit'); break;
          case 'submit': handleSubmit(event); break;
          default: break;
        }
      } 
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [view, items]);

  return (
    <div className="page-content">
      <form onSubmit={handleSubmit}>
        {view === 'intro' && (
          <div>
            <h3>1/6. Introduction to the "Purposeful Morning" Practice:</h3>
            <ol className="items-list">
              <li>The "Purposeful Morning" practice is basically identical to the Stoic practice of Morning Reflection, with the addition of the 6CED principles outlined in my book, 
                <a href="https://meaning.lifevisor.ai" target="_blank" rel="noreferrer">"An Engineer's Search for Meaning"</a>.</li>
              <li>It involves sitting quietly for just a few moments every morning and going over your goals or planned activities for the day, 
                idenifying challenges you may encounter while pursuing them, and visualizing the actions you might take to handle them.</li>              
              <li>In addition, the practice requires that one should ensure that these activities are "virtuous". In our case, we define virtuous as being in alignment with the 6CED Tendencies of the universe, in a life- and consciousness-enriching manner.</li>              
              <li>Doing this practice everyday has been proven to improve productivity, focus and peace of mind, as well as contributing to a sense of meaning, purpose and hope in our lives.</li>
              <li>Becoming really good at this practice is a lot harder than it sounds and takes a long time. But every little step can produce tangible benefits.</li>
              <li>So here is a quick exercise just to introduce the basic idea to you. It should take only a couple of minutes.</li>
              <li>And, to make it fun, you will get a little sharable memento at the end that might even inspire you to become creative!</li>
              <li>Click on Next when you are ready to start:</li>
            </ol>
            <div className="dialog-button-bar">
              <button  className="app-button" onClick={handleBackClick}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 0, 'identify')}>Next</button>
            </div>
          </div>
        )}
        {view === 'identify' && (
          <div>
            <h3>2/6. Identify Your Activities for the Day:</h3>
            <ol className="items-list">
              <li>Start by asking yourself what you need to accomplish today. These may be personal activities, work or school activities, or family or community activities.</li>
              <li>Make sure the descriptions of the activities are SMART (Specific, Measurable, Achievable, Relevant, Time-bound)</li>
              <li>Visualize yourself actually performing those activities and overcoming the challenges that may come up. Doing so may lead to insights that require modifying the activities.</li>
              <li>This Stoic practice requires us to ensure that all our activities are virtuous. In the context of this app, we will consider an activity to be virtuous if it is aligned with at least one of the 6CED Tendencies of the universe, in a life- and consciousness-enriching manner.</li>
              <li>Here is the list of the 6CED Tendencies for your reference: <b>Coherence or Comprehensibility, Complexity, Continuity of Existence or Identity, 
              Curiosity, Creativity, Conasciousness, Evolution and Diversity</b></li>
              <li>In the beginning, it may be hard to achieve this alignment for at least some of your activites, but over time, as you see the benefits, they should become better aligned.</li>
              <li>In the next 3 steps, we will enter up to 3 such activities you have identified for today. Click on Next when you are ready to enter the first activity:</li>
            </ol>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'intro')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 0, 'act1')}>Next</button>
            </div>
          </div>
        )}
        {view === 'act1' && (
          <div className="page-content">
            <h3>3/6. Activity #1:</h3>
            <div>
              <label htmlFor="item1"><b>Short description of activity #1:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-2"
                placeholder={`e.g. Complete a 30-minute HIIT workout`}
                maxLength="50"
                name="item1"
                id="item1"
                value={items["item1"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div>
              <p><b>Select one or more of the 6CED Tendencies the activity may be aligned with. (Some of these may overlap.):</b> </p>
                <input type="checkbox" name="tendencies1" id="coherence1" value={TendencyType.COHERENCE} checked={tendenciesList["tendencies1"] & TendencyType.COHERENCE} onChange={handleTendenciesChange} />
                <label htmlFor="coherence1"><b>Coherence/Comprehensibility</b> (Does the activity make your life or the world more coherent or comprehensible? E.g. learning or teaching something new.)</label><br />
                <input type="checkbox" name="tendencies1" id="complexity1" value={TendencyType.COMPLEXITY} checked={tendenciesList["tendencies1"] & TendencyType.COMPLEXITY} onChange={handleTendenciesChange} />
                <label htmlFor="complexity1"><b>Complexity</b> (Does the activity involve building or maintaining complex things? E.g. creating a complex dish from simple ingredients.)</label><br />
                <input type="checkbox" name="tendencies1" id="continuity1" value={TendencyType.CONTINUITY} checked={tendenciesList["tendencies1"] & TendencyType.CONTINUITY} onChange={handleTendenciesChange} />
                <label htmlFor="continuity1"><b>Continuity</b> (Does the activity involve building or maintaining things that last long? E.g. improving your health.)</label><br />
                <input type="checkbox" name="tendencies1" id="curiosity1" value={TendencyType.CURIOSITY} checked={tendenciesList["tendencies1"] & TendencyType.CURIOSITY} onChange={handleTendenciesChange} />
                <label htmlFor="curiosity1"><b>Curiosity</b> (Is the activity driven by curiosity? E.g. travel or meeting new people or exploring new ideas.)</label><br />
                <input type="checkbox" name="tendencies1" id="creativity1" value={TendencyType.CREATIVITY} checked={tendenciesList["tendencies1"] & TendencyType.CREATIVITY} onChange={handleTendenciesChange} />
                <label htmlFor="creativity1"><b>Creativity</b> (Will the activity create something new? E.g. a new product or service or idea etc.)</label><br />
                <input type="checkbox" name="tendencies1" id="consciousness1" value={TendencyType.CONSCIOUSNESS} checked={tendenciesList["tendencies1"] & TendencyType.CONSCIOUSNESS} onChange={handleTendenciesChange} />
                <label htmlFor="consciousness1"><b>Consciousness</b> (Does the activity involve a consciousness-enriching experience? E.g. playing great music or writing something meaningful.)</label><br />
                <input type="checkbox" name="tendencies1" id="evolution1" value={TendencyType.EVOLUTION} checked={tendenciesList["tendencies1"] & TendencyType.EVOLUTION} onChange={handleTendenciesChange} />
                <label htmlFor="evolution1"><b>Evolution</b> (Does the activity have the potential to evolve into something better? E.g. starting a new project or relationship.)</label><br />
                <input type="checkbox" name="tendencies1" id="diversity1" value={TendencyType.DIVERSITY} checked={tendenciesList["tendencies1"] & TendencyType.DIVERSITY} onChange={handleTendenciesChange} />
                <label htmlFor="diversity1"><b>Diversity</b> (Will the activity lead to increased diversity in the world or your own thinking? E.g. supporting something new and generally overlooked.)</label><br />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'identify')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 1, 'act2')}>Next</button>
            </div>
          </div>
        )}
        {view === 'act2' && (
          <div className="page-content">
            <h3>4/6. Activity #2:</h3>
            <div>
              <label htmlFor="item2"><b>Short description of activity #2:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-2"
                placeholder={`e.g. Finalize and submit the Acme proposal`}
                maxLength="50"
                name="item2"
                id="item2"
                value={items["item2"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div>
              <p><b>Select one or more of the 6CED Tendencies the activity may be aligned with. (Some of these may overlap.):</b> </p>
                <input type="checkbox" name="tendencies2" id="coherence2" value={TendencyType.COHERENCE} checked={tendenciesList["tendencies2"] & TendencyType.COHERENCE} onChange={handleTendenciesChange} />
                <label htmlFor="coherence2"><b>Coherence/Comprehensibility</b> (Does the activity make your life or the world more coherent or comprehensible? E.g. learning or teaching something new.)</label><br />
                <input type="checkbox" name="tendencies2" id="complexity2" value={TendencyType.COMPLEXITY} checked={tendenciesList["tendencies2"] & TendencyType.COMPLEXITY} onChange={handleTendenciesChange} />
                <label htmlFor="complexity2"><b>Complexity</b> (Does the activity involve building or maintaining complex things? E.g. creating a complex dish from simple ingredients.)</label><br />
                <input type="checkbox" name="tendencies2" id="continuity2" value={TendencyType.CONTINUITY} checked={tendenciesList["tendencies2"] & TendencyType.CONTINUITY} onChange={handleTendenciesChange} />
                <label htmlFor="continuity2"><b>Continuity</b> (Does the activity involve building or maintaining things that last long? E.g. improving your health.)</label><br />
                <input type="checkbox" name="tendencies2" id="curiosity2" value={TendencyType.CURIOSITY} checked={tendenciesList["tendencies2"] & TendencyType.CURIOSITY} onChange={handleTendenciesChange} />
                <label htmlFor="curiosity2"><b>Curiosity</b> (Is the activity driven by curiosity? E.g. travel or meeting new people or exploring new ideas.)</label><br />
                <input type="checkbox" name="tendencies2" id="creativity2" value={TendencyType.CREATIVITY} checked={tendenciesList["tendencies2"] & TendencyType.CREATIVITY} onChange={handleTendenciesChange} />
                <label htmlFor="creativity2"><b>Creativity</b> (Will the activity create something new? E.g. a new product or service or idea etc.)</label><br />
                <input type="checkbox" name="tendencies2" id="consciousness2" value={TendencyType.CONSCIOUSNESS} checked={tendenciesList["tendencies2"] & TendencyType.CONSCIOUSNESS} onChange={handleTendenciesChange} />
                <label htmlFor="consciousness2"><b>Consciousness</b> (Does the activity involve a consciousness-enriching experience? E.g. playing great music or writing something meaningful.)</label><br />
                <input type="checkbox" name="tendencies2" id="evolution2" value={TendencyType.EVOLUTION} checked={tendenciesList["tendencies2"] & TendencyType.EVOLUTION} onChange={handleTendenciesChange} />
                <label htmlFor="evolution2"><b>Evolution</b> (Does the activity have the potential to evolve into something better? E.g. starting a new project or relationship.)</label><br />
                <input type="checkbox" name="tendencies2" id="diversity2" value={TendencyType.DIVERSITY} checked={tendenciesList["tendencies2"] & TendencyType.DIVERSITY} onChange={handleTendenciesChange} />
                <label htmlFor="diversity2"><b>Diversity</b> (Will the activity lead to increased diversity in the world or your own thinking? E.g. supporting something new and generally overlooked.)</label><br />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'act1')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 2, 'act3')}>Next</button>
            </div>
          </div>
        )}
        {view === 'act3' && (
          <div className="page-content">
            <h3>5/6. Activity #3:</h3>
            <div>
              <label htmlFor="item3"><b>Short description of activity #3:</b> </label>
              <br/>
              <input
                type="text"
                className="form-control mb-2"
                placeholder={`e.g. Learn a new painting technique`}
                maxLength="50"
                name="item3"
                id="item3"
                value={items["item3"]}
                onChange={handlePromptItemChange}
              />
            </div>
            <div>
              <p><b>Select one or more of the 6CED Tendencies the activity may be aligned with. (Some of these may overlap.):</b> </p>
                <input type="checkbox" name="tendencies3" id="coherence3" value={TendencyType.COHERENCE} checked={tendenciesList["tendencies3"] & TendencyType.COHERENCE} onChange={handleTendenciesChange} />
                <label htmlFor="coherence3"><b>Coherence/Comprehensibility</b> (Does the activity make your life or the world more coherent or comprehensible? E.g. learning or teaching something new.)</label><br />
                <input type="checkbox" name="tendencies3" id="complexity3" value={TendencyType.COMPLEXITY} checked={tendenciesList["tendencies3"] & TendencyType.COMPLEXITY} onChange={handleTendenciesChange} />
                <label htmlFor="complexity3"><b>Complexity</b> (Does the activity involve building or maintaining complex things? E.g. creating a complex dish from simple ingredients.)</label><br />
                <input type="checkbox" name="tendencies3" id="continuity3" value={TendencyType.CONTINUITY} checked={tendenciesList["tendencies3"] & TendencyType.CONTINUITY} onChange={handleTendenciesChange} />
                <label htmlFor="continuity3"><b>Continuity</b> (Does the activity involve building or maintaining things that last long? E.g. improving your health.)</label><br />
                <input type="checkbox" name="tendencies3" id="curiosity3" value={TendencyType.CURIOSITY} checked={tendenciesList["tendencies3"] & TendencyType.CURIOSITY} onChange={handleTendenciesChange} />
                <label htmlFor="curiosity3"><b>Curiosity</b> (Is the activity driven by curiosity? E.g. travel or meeting new people or exploring new ideas.)</label><br />
                <input type="checkbox" name="tendencies3" id="creativity3" value={TendencyType.CREATIVITY} checked={tendenciesList["tendencies3"] & TendencyType.CREATIVITY} onChange={handleTendenciesChange} />
                <label htmlFor="creativity3"><b>Creativity</b> (Will the activity create something new? E.g. a new product or service or idea etc.)</label><br />
                <input type="checkbox" name="tendencies3" id="consciousness3" value={TendencyType.CONSCIOUSNESS} checked={tendenciesList["tendencies3"] & TendencyType.CONSCIOUSNESS} onChange={handleTendenciesChange} />
                <label htmlFor="consciousness3"><b>Consciousness</b> (Does the activity involve a consciousness-enriching experience? E.g. playing great music or writing something meaningful.)</label><br />
                <input type="checkbox" name="tendencies3" id="evolution3" value={TendencyType.EVOLUTION} checked={tendenciesList["tendencies3"] & TendencyType.EVOLUTION} onChange={handleTendenciesChange} />
                <label htmlFor="evolution3"><b>Evolution</b> (Does the activity have the potential to evolve into something better? E.g. starting a new project or relationship.)</label><br />
                <input type="checkbox" name="tendencies3" id="diversity3" value={TendencyType.DIVERSITY} checked={tendenciesList["tendencies3"] & TendencyType.DIVERSITY} onChange={handleTendenciesChange} />
                <label htmlFor="diversity3"><b>Diversity</b> (Will the activity lead to increased diversity in the world or your own thinking? E.g. supporting something new and generally overlooked.)</label><br />
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'act2')}>Back</button>
              <button className="app-button" onClick={(e) => updateView(e, 3, 'submit')}>Next</button>
            </div>
          </div>
        )}
        {view === 'submit' && (
          <div className="page-content">
            <h3>6/6. That's it! Now it's time for your prize:</h3>
            <ol className="items-list">
              <li>All the activity descriptions you have entered during this practice will now be fed to the MindFeed AI and it will create a fun 'memento' based on them.</li>
              <li>You can choose the type of memento you want below.</li>
              <li>You can share it with your fiends. Doing so lets you "pay if forward" by introducing your friends to this highly beneficial practice.</li>
              <li>Note that, while the practice itself is quite serious, the memento is only meant as a fun and creative souvenir. It shouldn't be taken too seriously (though it should be a lot better than most of the trash you encounter on social media!)</li>
              <li>Signed in users can save the memento to their profile or even edit it to their liking.</li>
              <li>Return to MindFeed on a daily basis and you will start to see its benefits over time.</li>
              <li>Note that MindFeed is intended just as a quick introduction to these practices. Eventually you may want to take lessons from a real expert.</li>
            </ol>
            <div>
              <p><b>Choose the type of memento and submit:</b> </p>
              <p className="indented">[Short, generic memento types] </p>
              <input type="radio" id="shortpoem" name="subtype" value={MementoSubtype.SHORTPOEM} checked={subtype === MementoSubtype.SHORTPOEM} onChange={handleSubtypeChange} />
              <label htmlFor="shortpoem">Short Poem / Haiku</label><br />
              <input type="radio" id="quote" name="subtype" value={MementoSubtype.QUOTE} checked={subtype === MementoSubtype.QUOTE} onChange={handleSubtypeChange} />
              <label htmlFor="quote">Fortune Cookie / Quote</label><br />
              <p className="indented">[Longer, personalized memento types]</p>
              <input type="radio" id="poem" name="subtype" value={MementoSubtype.POEM} checked={subtype === MementoSubtype.POEM} onChange={handleSubtypeChange} />
              <label htmlFor="poem">Poem</label><br />
              <input type="radio" id="rap" name="subtype" value={MementoSubtype.RAP} checked={subtype === MementoSubtype.RAP} onChange={handleSubtypeChange} />
              <label htmlFor="rap">Rap Song</label><br />
              <input type="radio" id="scifi" name="subtype" value={MementoSubtype.SCIFI} checked={subtype === MementoSubtype.SCIFI} onChange={handleSubtypeChange} />
              <label htmlFor="scifi">Sci-fi Inspiration</label><br />              
              <input type="radio" id="romance" name="subtype" value={MementoSubtype.ROMANCE} checked={subtype === MementoSubtype.ROMANCE} onChange={handleSubtypeChange} />
              <label htmlFor="romance">Romance Inspiration</label><br />                            
            </div>
            <div className="dialog-button-bar">
              <button className="app-button" onClick={(e) => updateView(e, 0, 'act3')}>Back</button>
              <button type="submit" className="app-button">Submit</button>
            </div>
          </div>
        )}
      </form>
      <Modal show={waitingMessage} title="Please Wait" onClose={() => setWaitingMessage('')}>
        <p>{waitingMessage}</p>
      </Modal>
      <Modal show={errorMessage} title="Error" onClose={() => setErrorMessage('')}>
        <p>{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default MorningReflection;
