import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormatMemento from './FormatMemento';
import ShareMemento from './ShareMemento';
import { encodeMementoId, backColorFromMemento, fontColorFromMemento, practiceFromMementoType } from './Utils';
import '../App.css';

const FeedItem = ({ memento }) => {
  const [likes, setLikes] = useState(memento.likes);
  const navigate = useNavigate();

  // if memento.top_img_name is null, it means the img is in db instead of as an img file on disk and should be accessed using the encoded memento.id
  if (!memento.top_img_name)
    memento.top_img_name = encodeMementoId(memento.id);

  const handleLike = () => {
    setLikes(likes + 1);
    // TODO: Update backend
  };

  const handleTitleClick = () => {
    navigate('/memento/' + encodeMementoId(memento.id));
  };

  return (
    <div className="feed-item" style={{ backgroundColor: backColorFromMemento(memento), color: fontColorFromMemento(memento) }}>
      <div className="memento-top-info">
        <span className="username">@{(memento.user && memento.user.username) ? memento.user.username : 'Anonymous'}</span>
        <span className="date">{new Date(memento.timestamp).toLocaleDateString()}</span>
      </div>
      <div className="memento">
        <div>
          <img className="memento-top-img" src={'/top_img/' + memento.top_img_name} onClick={handleTitleClick} alt={memento.title} />
        </div>
        <p className="memento-title" onClick={handleTitleClick}>{memento.title}</p>
        <p className="memento-type">[A {practiceFromMementoType(memento.type)}]</p>
        <div className="memento-content">
          <FormatMemento mementoContent={memento.content} />
        </div>
      </div>
      <div className="memento-actions-bar">
        <button className="app-button" onClick={handleLike}><img src="/like.svg" width="16" height="16" alt="Like" title="Like" /> Like ({likes})</button>
        <ShareMemento memento={memento} />
      </div>
    </div>
  );
};

export default FeedItem;
