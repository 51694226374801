import React, { useState } from 'react';
import { signInUsingFirebase, signInWithGoogle } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import { useUser, updateUserData } from './UserContext';
import '../App.css';

const Login = () => {
    const { currentUser } = useUser();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [googleErrorMessage, setGoogleErrorMessage] = useState('');

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInUsingFirebase(email, password);
            navigate('/profile');
        } catch (error) {
            console.log(error);
            setErrorMessage('Invalid email or password. Please try again.');
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const userCredential = await signInWithGoogle();
            await updateUserData(userCredential.user);
            navigate('/profile');
        } catch (error) {
            console.log(error);
            setGoogleErrorMessage('Invalid credentials. Please try again.');
        }
    };

    if (currentUser) {
        window.location.href = '/profile';
    }
    else {
        return (
            <div className="form-page">
                <h2>Log In</h2>
                <form onSubmit={handleLogin}>
                    <div className="center">
                        <input id="email" type="email" value={email} maxLength={255} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                    </div>
                    <div className="center">
                        <input id="password" type="password" value={password} maxLength={100} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                    </div>
                    <div className="center">
                        <p className="errorMessage">{errorMessage}</p>
                    </div>
                    <div>
                        <button className="app-button" type="submit">Login</button>
                    </div>
                </form>
                <p className="horizontal-line" />
                <div>
                    <p>OR</p>
                    <button className="app-button" onClick={handleGoogleSignIn}>Continue with Google</button>
                </div>
                <div className="center">
                    <p className="errorMessage">{googleErrorMessage}</p>
                </div>
                <p className="horizontal-line" />
                <div>
                    <p>New to MindFeed? <button className="app-button" onClick={() => navigate('/signup')}>Sign Up</button></p>
                </div>
            </div>
        );
    }
};

export default Login;
