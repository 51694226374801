import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function Intro6CEDReflection() {
  const navigate = useNavigate();

  const handleHome = () => {
      navigate('/');
  };

  return (
    <div className="page-content">
      <h2>"6CED Tendencies" - A Quick Intro</h2>
      <p>In my book <a href="https://meaning.lifevisor.ai" target="_blank" rel="noreferrer">"An Engineer's Search for Meaning"</a>, I show how the universe can be scientifically proven to be exhibiting the following inherent, ubiquitous and omnipresent tendencies:</p>
      <ul>
        <li><b>Coherence or Comprehensibility</b></li>
        <li><b>Complexity</b></li>
        <li><b>Continuity of Existence or Identity</b></li>
        <li><b>Curiosity</b></li>
        <li><b>Creativity</b></li>
        <li><b>Consciousness</b></li>
        <li><b>Evolution</b></li>
        <li><b>Diversity</b></li>
      </ul>

      <p>I call these tendencies the "6CED Tendencies" given that the list contains 6 tendencies starting with "C", one with an "E" and one with a "D".</p>
      <p>The book further goes on to prove how all living entities, such as ourselves, can make their lives meaningful, purposeful and hopeful by aligning themselves with these tendencies.</p>

      <h3>Finding Meaning, Purpose and Hope in Life by Aligning Oneself with the "6CED" Tendencies</h3>

      <p>Various scientific studies have shown that when people talk about "Meaning in Life", they are typically referring to the following three desires:</p>
      <ul>
        <li><b>Having a Sense of Coherence or Comprehensibility in Life</b></li>
        <li><b>Having a Sense of Purpose in Life</b></li>
        <li><b>Having a Sense of Existential Mattering or Significance in Life</b></li>
      </ul>

      <p>The book goes on to show how these desires can be satisfied by the 6CED Tendencies mentioned earlier.</p>
      <p>The book further shows why aligning your life with these tendencies can make your life more meaningful, purposeful and hopeful, without having to take any leaps of faith or relying on someone's opinion.</p>

      <p>In order to put this idea into practice, I have developed new practices called "Purposeful Mornings", "Hopeful Evenings" and "Meaningful Life".</p>
      <p>One the Home page, you will find buttons associated with each of these practices. You can learn more about these practices and actually practice them by clicking on those buttons.</p>
            
      <p>By integrating these practices into your life, you can leverage a scientifically-backed method to make your life more meaningful, purposeful and hopeful.</p>

      <div className="dialog-button-bar">
          <button className="app-button" onClick={handleHome}>Home</button>
      </div>
    </div>
  );
}

export default Intro6CEDReflection;
