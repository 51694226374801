import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import './CreateBar.css';

const CreateBar = ({ user }) => {
    const [showHelp, setShowHelp] = useState(false);
    const [showEvening, setShowEvening] = useState(false);
    const [showLife, setShowLife] = useState(false);

    const navigate = useNavigate();

    const handleMindfulnessClick = () => {
        navigate('/observe');
    };
  
    const handleMorningClick = () => {
        navigate('/morning');
    };
  
    const handleEveningClick = () => {
        setShowEvening(true);
        // navigate('/evening');
    };

    const handleLifeClick = () => {
        setShowLife(true);
        // navigate('/life');
    };

    const getFirstName = (name) => {
        var firstName = name.split(' ')[0];
        firstName = firstName.substring(0, 16);
        return firstName;
    };

    return (
        <div className="create-bar">
            <div className="instructions-container">
                <h3>Start your practice{user ? `, ${getFirstName(user.displayName)}` : ""}: </h3>
                <button className="help-button" onClick={() => setShowHelp(true)}>?</button>
                <Modal show={showHelp} title="Daily Practice Help" onClose={() => setShowHelp(false)}>
                    <ol className="items-list">
                        <li>MindFeed helps you get started on basic Mindfulness as well as Stoic and "6CED" Reflection practices in a fun way.</li>
                        <li>These practices have a strong scientific and philosophical foundation and there is a lot of evidence that they lead to healthier, happier, more productive and more meaningful lives.</li>
                        <li>Click on the practice you want to learn. MindFeed will guide you through the practice.</li>
                        <li>Each practice session takes only a few minutes, and at the end MindFeed will generate a fun, sharable memento of your session.</li>
                        <li>Signed in users can also edit and save the memento into their account and display it on their profile.</li>
                        <li>In addition, MindFeed builds a downloadable journal to document your progression.</li>
                        <li>Note that MindFeed only gives you a quick intro to the aforementioned practices. Eventually, you will need to learn the practice in more depth from an expert.</li>
                    </ol>
                    <p>Get started on an adeventure towards a more peaceful, productive and happy you!</p>
                </Modal>
            </div>
            <div className="create-container">
                <button className="app-button" onClick={handleMindfulnessClick}>Mindful Moments</button>
                <button className="app-button" onClick={handleMorningClick}>Purposeful Mornings</button>
                <button className="app-button" onClick={handleEveningClick}>Hopeful Evenings</button>
                <Modal show={showEvening} title="Hopeful Evening" onClose={() => setShowEvening(false)}>
                    <div>Coming soon: Stepwise guidance to practice Evening Reflection (a Stoic practice) combined with meaning alignment using the "6CED" principles, and journal the experience in a fun and memorable way.</div>
                </Modal>
                <button className="app-button" onClick={handleLifeClick}>Meaningful Life</button>
                <Modal show={showLife} title="Meaningful Life" onClose={() => setShowLife(false)}>
                    <div>Coming soon: Stepwise guidance for discovering meaning in life by following scientifically sound "6CED" principles, and journal the experience in a fun and memorable way.</div>
                </Modal>
            </div>
        </div>
    );
};

export default CreateBar;
