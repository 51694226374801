import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Home from './components/Home';
import Observe from './components/Observe';
import MorningReflection from './components/MorningReflection';
import Memento from './components/Memento';
import NotFound from './components/NotFound';
import UserProfile from './components/UserProfile';
import About from './components/About';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Logout from './components/Logout';
import IntroMindfulness from './components/IntroMindfulness';
import IntroStoicReflection from './components/IntroStoicReflection';
import Intro6CEDReflection from './components/Intro6CEDReflection';
import TitleBar from './components/TitleBar'
import { useUser } from './components/UserContext';
import ProtectedRoute from './components/ProtectedRoute';
import PageTitle from './components/PageTitle'; 
import './App.css';

// init Google analytics
ReactGA.initialize('G-9DZ5H3RTGX');

function App() {
  const { currentUser } = useUser();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });
  }, []);

  return (
    <Router>
      <TitleBar />
      <div className="app">
        <nav id="sidebar-left">
          <p><a href="/">Home</a></p>
          <p>{ currentUser ? <a href="/profile">Profile</a> : <a href="/login">Login</a> }</p>
          <p>Resources</p>
          <p className="indented"><a href="/mindfulness">Mindfulness</a></p>
          <p className="indented"><a href="/stoicref">Stoic Reflection</a></p>
          <p className="indented"><a href="/6cedref">"6CED" Reflection</a></p>
          <p><a href="/about">About</a></p>
        </nav>
        <main id="main-content">
          <Routes>
            <Route path="/" exact element={
              <>
                <PageTitle />
                <Home />
              </>
            } />
            <Route path="/observe" element={
              <>
                <PageTitle title="Mindful Moment" />
                <Observe />
              </>
            } />
            <Route path="/morning" element={
              <>
                <PageTitle title="Purposeful Morning" />
                <MorningReflection />
              </>
            } />
            <Route path="/memento/:id" element={
              <>
                <PageTitle title="Memento" />
                <Memento />
              </>
            } />
            <Route path="/about" element={
              <>
                <PageTitle title="About" />
                <About />
              </>
            } />
            <Route path="/logout" element={
              <>
                <PageTitle title="Logout" />
                <Logout />
              </>
            } />
            <Route path="/profile" element={
              <>
                <PageTitle title="Profile" />
                <ProtectedRoute><UserProfile /></ProtectedRoute>
              </>
            } />
            <Route path="/signup" element={
              <>
                <PageTitle title="Signup" />
                <SignUp />
              </>
            } />
            <Route path="/login" element={
              <>
                <PageTitle title="Login" />
                <Login />
              </>
            } />
            <Route path="/mindfulness" element={
              <>
                <PageTitle title="Mindfulness - A Quick Intro" />
                <IntroMindfulness />
              </>
            } />
            <Route path="/stoicref" element={
              <>
                <PageTitle title="Stoic Reflection and Journaling - A Quick Intro" />
                <IntroStoicReflection />
              </>
            } />
            <Route path="/6cedref" element={
              <>
                <PageTitle title="6CED Reflection - A Quick Intro" />
                <Intro6CEDReflection />
              </>
            } />
            <Route element={
              <>
                <PageTitle title="Page Not Found" />
                <NotFound />
              </>
            } />
          </Routes>
        </main>
        <aside id="sidebar-right">
          <h2>Welcome to MindFeed - Fun Intros to Proven Practices for Creating Meaningful Lives, Assisted by Generative AI!</h2>
          <p></p>
          <h3>Quick intro:</h3>
          <ol className="items-list">
              <li>MindFeed helps you get started on proven wellness practices such as Mindfulness, Stoic Reflection and "6CED" Reflection, in a fun way.</li>
              <li>These practices have a strong scientific and philosophical foundation and there is a lot of evidence that they lead to healthier, happier, more productive and more meaningful lives.</li>
              <li>Click on the practice you want to learn. MindFeed will guide you through the practice.</li>
              <li>Each practice session takes only a few minutes, and at the end MindFeed will automatically generate a fun, sharable memento of your session.</li>
              <li>Users who have signed in can also edit and save the memento into their account and display it on their profile.</li>
              <li>In addition, MindFeed builds a downloadable journal to document your progression.</li>
              <li>Note that MindFeed only gives you a quick intro to the aforementioned practices. Eventually, you will need to learn the practice in more depth from an expert.</li>
          </ol>
          <p>Get started on an adeventure towards a more peaceful, productive and happy you!</p>
        </aside>
      </div>
    </Router>
  );
}

export default App;
