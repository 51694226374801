import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth } from '../firebase-config'; // Ensure you import your initialized auth object

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe; // Function to be called on component unmount
    }, []);

    return (
        <UserContext.Provider value={{ currentUser, loading }}>
            {!loading && children}
        </UserContext.Provider>
    );
};

// inform backend about the new sign up
// this method should be called from inside a try catch block
export const updateUserData = async (user, displayName = null, username = null) => {
    const idToken = await getIdToken(user);
    const response = await fetch("/api_user", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify({ displayName: displayName, username: username })
    });
    
    if (response.ok) {
        await response.json();
    }
    else {
        throw new Error(response.error); 
    }
};
