import React, { useState } from 'react';
import Modal from './Modal';
import '../App.css';
import { practiceFromMementoType, encodeMementoId } from './Utils';

const ShareMemento = ({ memento }) => {
    const [showSharing, setShowSharing] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const shareInfo = {
        title: 'Check out my MindFeed memento',
        text: `I just did a "${practiceFromMementoType(memento.type)}" practice session using a new self-help app called MindFeed. Check out this memento it generated for me as a prize: `,
        url: `${window.location.origin}/memento/${encodeMementoId(memento.id)}`,
    };
    const fullText = shareInfo.text + shareInfo.url;

    const shareViaWhatsApp = () => {
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(fullText)}`;
        window.open(whatsappUrl, '_blank');
        setShowSharing(false);
    };

    const shareViaFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareInfo.url)}`;
        window.open(facebookUrl, '_blank');
        setShowSharing(false);
    };

    const shareViaTwitter = () => {
        const facebookUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareInfo.text)}&url=${encodeURIComponent(shareInfo.url)}`;
        window.open(facebookUrl, '_blank');
        setShowSharing(false);
    };

    const shareViaEMail = () => {
        const mailtoUrl = `mailto:?subject=${encodeURIComponent(shareInfo.title)}&body=${encodeURIComponent(fullText)}`;
        window.open(mailtoUrl, '_self');
        setShowSharing(false);
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareInfo.url);
            setShowSharing(false);
            setPopupVisible(true);
            setTimeout(() => setPopupVisible(false), 2000); // Hide the popup after 2 seconds
        } catch (err) {
            console.log('Failed to copy: ', err);
        }
    };
    
    return (
        <div>
            <button className="app-button" onClick={() => setShowSharing(true)}><img src="/share.svg" width="16" height="16" alt="Share" title="Share" /> Share</button>
            <Modal show={showSharing} title="Share Memento" onClose={() => setShowSharing(false)}>
                <div>Share this memento with your friends:</div>
                <div>&nbsp;</div>
                <div>
                    <div>
                        <button class="share-button" onClick={shareViaWhatsApp}>
                            <svg class="share-icons" fill="#68C05F" height="20" width="20" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 308 308">
                                <g>
                                    <path d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                                        c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                                        c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                                        c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                                        c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                                        c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                                        c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                                        c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                                        c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                                        C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                    <path d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                                        c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                                        c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                                        M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                                        l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                                        c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                                        C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                                </g>
                            </svg>
                            <span class="share-text">Share via WhatsApp</span>
                        </button>
                    </div>
                    <div>
                        <button class="share-button" onClick={shareViaFacebook}>
                            <svg class="share-icons" role="img" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke-width="1.8" stroke="#555555" xmlns="http://www.w3.org/2000/svg" size="20"><g><title></title><g clip-path="url(#clip0_2063_103618)"><circle cx="12" cy="12" r="12" fill="white" stroke="none"></circle><path d="M9.101 23.647V15.667H6.627V12H9.101V10.42C9.101 6.335 10.949 4.442 14.959 4.442C15.36 4.442 15.914 4.484 16.427 4.545C16.8112 4.58454 17.1924 4.64968 17.568 4.74V8.065C17.3509 8.04475 17.133 8.03274 16.915 8.029C16.6707 8.02266 16.4264 8.01966 16.182 8.02C15.475 8.02 14.923 8.116 14.507 8.329C14.2273 8.46931 13.9922 8.68468 13.828 8.951C13.57 9.371 13.454 9.946 13.454 10.703V12H17.373L16.987 14.103L16.7 15.667H13.454V23.912C19.396 23.194 24 18.135 24 12C24 5.373 18.627 0 12 0C5.373 0 0 5.373 0 12C0 17.628 3.874 22.35 9.101 23.647Z" fill="#0866FF" stroke="none"></path></g><defs><clipPath id="clip0_2063_103618"><rect width="24" height="24" fill="white" stroke="none"></rect></clipPath></defs></g></svg>
                            <span class="share-text">Share via Facebook</span>
                        </button>
                    </div>
                    <div>
                        <button class="share-button" onClick={shareViaTwitter}>
                            <svg class="share-icons" role="img" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke-width="1.8" stroke="#555555" xmlns="http://www.w3.org/2000/svg" size="20"><g><title></title><path d="M17.7508 2.96069H20.8175L14.1175 10.619L22 21.0382H15.8283L10.995 14.7182L5.46333 21.0382H2.395L9.56167 12.8465L2 2.96153H8.32833L12.6975 8.73819L17.7508 2.96069ZM16.675 19.2032H18.3742L7.405 4.69986H5.58167L16.675 19.2032Z" fill="#555555" stroke="none"></path></g></svg>
                            <span class="share-text">Share via X / Twitter</span>
                        </button>
                    </div>
                    <div>
                        <button class="share-button" onClick={shareViaEMail}>
                            <svg class="share-icons" fill="#000000" height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64">
                                <path d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6
                                    h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017
                                    L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003
                                    L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14
                                    C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z
                                    M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401
                                    c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981
                                    l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z"/>
                            </svg>
                            <span class="share-text">Share via EMail</span>
                        </button>
                    </div>
                    <div>
                        <button class="share-button" onClick={copyToClipboard}>
                            <svg class="share-icons" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                            <span class="share-text">Copy link to clipboard</span>
                        </button>
                    </div>
                </div>
            </Modal>
            {popupVisible && <div className="popup">Link copied to clipboard!</div>}
        </div>
    );
};

export default ShareMemento;
