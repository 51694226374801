import React from 'react';

function splitIntoLines(passage) {
    // Split the passage by newlines first
    const lines = passage.split('\n');

    // Define the regular expression to split by sentences
    const sentenceRegex = /(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<![A-Z]\.)(?<!\.\.\.)(?<![A-Z][a-z]\.\.\.)(?<=\.|\?|!|")\s/g;
    
    // Split each line into sentences and combine all into one array
    const sentences = lines.flatMap(line => {
        const matchedSentences = line.split(sentenceRegex);
        
        // If there are matched sentences, return them trimmed; otherwise, return the original line trimmed
        return matchedSentences ? matchedSentences.map(sentence => sentence.trim()) : [line.trim()];
    });

    return sentences;
}
  
class FormatMemento extends React.Component {
    render() {
        const { mementoContent } = this.props;
        let mementoLines = [];

        mementoLines = splitIntoLines(mementoContent).map((line, index) => {
            return <p key={index}>{line}</p>;
        });

        return (
            <div>
                {mementoLines}
            </div>
        );
    }
}

export default FormatMemento;
