import { signOutUsingFirebase } from '../firebase-config';

function Logout() {
  const handleLogout = async () => {
    await signOutUsingFirebase();
    window.location.href = '/';
  };

  handleLogout();
}

export default Logout;
