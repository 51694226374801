import React, { useState, useEffect } from 'react';
import Feed from './Feed';
import CreateBar from './CreateBar'
import { useUser } from './UserContext';
import '../App.css';

function Home() {
    const { currentUser } = useUser();
    const [mementos, setMementos] = useState([]);

  useEffect(() => {
      const fetchMementos = async () => {
          try {
              const response = await fetch('/api_feed');
              if (response.ok) {
                  const data = await response.json();
                  setMementos(data);
              } else {
                  throw new Error('Failed to fetch mementos');
              }
          } catch (error) {
              console.log('Error: ', error);
          }
      };

      fetchMementos();
  }, []);

  const handleTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <CreateBar user={currentUser} />
      <Feed mementos={mementos} />
      <div className="feed-message">Note: MindFeed does not want to encourage doomscrolling. Please go back to the top, click on one of the practice buttons and create your own healthy content.</div>
      <button className={'app-button center'} onClick={handleTop}>Back to Top</button>
  </div>
)};
  
export default Home;
