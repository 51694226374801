import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut, onAuthStateChanged, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCG1ZMXFmK7-VjkW7TlMq_ksZYhkTimqII",
  authDomain: "mf101-421201.firebaseapp.com",
  projectId: "mf101-421201",
  storageBucket: "mf101-421201.appspot.com",
  messagingSenderId: "1046476307371",
  appId: "1:1046476307371:web:617ae806ac5524ed6d30ee",
  measurementId: "G-72RZMEPH6S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// const analytics = getAnalytics(app);

// Email and password signup / login
export const signUpUsingFirebase = async (email, password) => {
  try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      return userCredential;
  } catch (error) {
      throw error;
  }
};

export const signInUsingFirebase = async (email, password) => {
  try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential;
  } catch (error) {
      throw error;
  }
};

export const signOutUsingFirebase = async () => {
  try {
      await signOut(auth);
  } catch (error) {
      throw error;
  }
};

// Google login
export const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
        const userCredential = await signInWithPopup(auth, provider);
        console.log('Google auth successful', userCredential);
        return userCredential;
    } catch (error) {
        console.log('Google auth error: ', error);
        throw error;
    }
};

export const signInWithFacebook = async () => {
  const facebookAuthProvider = new FacebookAuthProvider();
  try {
    const userCredential = await signInWithPopup(auth, facebookAuthProvider);
    console.log(userCredential);
  } catch (error) {
    console.log('Facebook auth error: ', error);
  }
};

export { auth, onAuthStateChanged };
